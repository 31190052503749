

































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import './scss/BaseLink.scss';

@Component({
    name: 'BaseLink',
})

export default class BaseLink extends Vue {
    @Prop({ default: '#!' }) url!: string;
    @Prop({ default: '' }) text!: string;
    @Prop({ default: '_self' }) target!: string;
    @Prop({ default: false }) showSignature!: boolean;
}
